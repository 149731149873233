import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HeroBanner = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHeroBanner {
        data {
          quip {
            text
          }
          heropicture {
            localFile {
              childImageSharp {
                id
                fluid(quality: 95) {
                  ...GatsbyImageSharpFluid
                }            
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div class="hero-picture">
      <Img fluid={data.prismicHeroBanner.data.heropicture.localFile.childImageSharp.fluid} />
      <div class="hero-text">
        <h1>Welcome</h1>
        </div>
    </div>
  );
}

export default HeroBanner

