import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import Layout from "../components/layout"
import Person from "../components/person"
import SEO from "../components/seo"
import Title from "../components/title"
import HeroBanner from "../components/herobanner"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Title title="Angelfire" />
    <HeroBanner />
    <img src="https://source.unsplash.com/random/400x200" alt="" />
    <Person />

  </Layout>
)

export default IndexPage
